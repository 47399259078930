import { SyntheticEvent, useCallback } from 'react'
import { BoxProps, LinkProps, useDisclosure, usePrefersReducedMotion } from '@chakra-ui/react'
import { MotionProps } from 'framer-motion'
import debounce from 'lodash/debounce'
import { Entry } from '../hooks/useContentstack'

type UseMegaMenuParams = {
  item: { href: string; hasChildren?: boolean; children?: Entry[] }
  routerPush: (href: string) => void
}

type UseMegaMenuContent<T> = {
  rootProps: BoxProps
  linkProps: LinkProps
  childrenLinkProps: LinkProps
  motionProps: MotionProps
  reducedMotion: boolean
  renderContent: boolean
}

export function useMegaMenu<T>({ routerPush, item }: UseMegaMenuParams): UseMegaMenuContent<T> {
  const prefersReducedMotion = usePrefersReducedMotion()
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const { href, hasChildren, children } = item

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/Event/currentTarget
   * Note: The value of event.currentTarget is only available while the event is being handled.
   * If you console.log() the event object, storing it in a variable, and then look for the
   * currentTarget key in the console, its value will be null`.
   *
   * With that in mind, we had to replace debounce with setTimeout to handle the onBlur event.
   * So we store the currentTarget in a variable and then check if the new focused element is a child of the original container.
   */
  const handleBlur =
    // https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
    (e: SyntheticEvent) => {
      const currentTarget = e.currentTarget

      setTimeout(() => {
        // Give browser time to focus the next element
        requestAnimationFrame(() => {
          // Check if the new focused element is a child of the original container
          if (!currentTarget?.contains(document.activeElement)) {
            onClose()
          }
        })
      }, 500)
    }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // On the Desktop MegaMenu, close the menu after click
    onToggle()
  }

  return {
    rootProps: {
      onMouseLeave: () => onClose(),
      onBlur: handleBlur,
    },
    linkProps: {
      onMouseEnter: () => onOpen(),
      onClick: handleLinkClick,
      onKeyDown: (e) => {
        if (e.code === 'Enter') {
          e.preventDefault()
          if (isOpen || !hasChildren) {
            href && routerPush(href)
          }
          onToggle()
        }
        if (e.code === 'ArrowDown' && !isOpen) {
          e.preventDefault()
          onToggle()
        }
        if (e.code === 'Space') {
          e.preventDefault()
          onToggle()
        }
        if (e.code === 'Escape') {
          onClose()
        }
      },
      'aria-haspopup': hasChildren,
    },
    childrenLinkProps: {
      onClick: handleLinkClick,
      onKeyDown: (e) => {
        if (['Enter', 'Space', 'Escape'].includes(e.code)) {
          onToggle()
        }
      },
    },
    reducedMotion: prefersReducedMotion,
    renderContent: Boolean(isOpen && hasChildren && children?.length >= 1),
    motionProps: getMotionProps(prefersReducedMotion),
  }
}

const getMotionProps = (reducedMotion?: boolean): MotionProps => {
  const props: MotionProps = {
    style: {
      margin: '0!important',
      position: 'absolute',
      top: '100%',
      left: '0',
      width: '100%',
      transformOrigin: '50% 0',
    },
  }

  return props
}
