import { useState, useEffect } from 'react'
import { Skeleton } from '@chakra-ui/react'
import {
  ContentstackBannerFull as ContentstackBannerFullProps,
  ContentstackBannerSlider as ContentstackBannerSliderProps,
  ContentstackBannerSplit as ContentstackBannerSplitProps,
  ContentstackBannerTextOnly as ContentstackBannerTextOnlyProps,
  ContentstackReferencedEntry,
  BannerSlidersType,
} from '@Types/contentstack'
import { BannerSlider } from '../cms-components/banner-slider'
import useContentstack from '../hooks/useContentstack'

export const ContentstackBannerSlider = ({
  placeholder_id,
  slider_title,
  title_font_family,
  title_font_size,
  heading_alignment,
  eyebrow,
  description,
  banners,
  desktop_container_width,
  analyticsTrackingData,
  isMobileDevice,
}: ContentstackBannerSliderProps) => {
  const [contentList, setContentList] = useState<BannerSlidersType[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    const getGridContent = async (items: ContentstackReferencedEntry[]) => {
      try {
        const itemsResults = items?.map((item) => getEntryByUid(item._content_type_uid, item.uid))
        const results = (await Promise.all(itemsResults)) as unknown as BannerSlidersType[]

        setContentList(results)
      } catch (err) {
        console.error('Error processing Banner slider data', err)
      }
    }

    getGridContent(banners)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <Skeleton />

  return (
    <BannerSlider
      placeholder_id={placeholder_id}
      isMobileDevice={isMobileDevice}
      sliderTitle={slider_title}
      title_font_family={title_font_family}
      title_font_size={title_font_size}
      heading_alignment={heading_alignment}
      eyebrow={eyebrow}
      description={description}
      banners={contentList}
      desktopContainerWidth={desktop_container_width}
      analyticsTrackingData={analyticsTrackingData}
    />
  )
}
